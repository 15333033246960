import '../../custom.scss';

import React, { useState } from "react";

const Search = (props) => {
  const [name, setName] = useState("");
  const [namerr, setNameerr] = useState("");

  const handleSearch = () => {
    if (name === "") {
      setNameerr("Please enter your company name");
      return;
    }
    props.history.push("/company-select",{companyName:name});
  };
  return (
    <div>
      {" "}
      <section className="container">
        <section className="wrapper">
          <h3 className="h3">Search your company</h3>
          <section className="light_bg_card">
            <input
              className="input-normal"
              placeholder="Enter company name"
              name="name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setNameerr("")
              }}
            />

           <div className='center'>
           {namerr ? <span className="error">{namerr}</span> : null}
              <button onClick={handleSearch} className="button-normal">
              Search
            </button></div>
          </section>
        </section>
      </section>
    </div>
  );
};

export default Search
