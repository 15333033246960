import '../../custom.scss';

import React from 'react'

const Company = (props) => {
    return (
        <div>
            <section className="container">
                <section className="wrapper">
                    <h3 className="h3">Enroll to get your salary advance</h3>
                    <section className="light_bg_card">
                        <input className="input-normal" placeholder="Company Name"/>
                        <div className="center">
                            <button onClick={() => {
                                props.history.push("/company-hr")
                            }} className="button-normal">Next
                            </button>
                        </div>
                    </section>
                </section>
            </section>
        </div>
    )
}

export default Company
