import React, { useState } from 'react';
import Cookies from "universal-cookie";


const cookies = new Cookies();

const SendVerification = (props) => {
  const[officeEmail,setOfficeEmail]=useState("");
  const handleVerificatin =()=>{
    cookies.set('officeEmail', officeEmail);
    props.history.push("/get-salary-advance",{officeEmail:officeEmail})
  }
  
  return (
    <div><section className="container">
    <section className="wrapper">
        <h3 className="h3">Select your company</h3>
        <section className="light_bg_card">
            <input className="input-normal" placeholder={props?.location?.state?.companyName} disabled/>
            <label className="label">Company Email id</label>
            <input className="input-normal" placeholder="Enter email" value={officeEmail} onChange={(e)=>{
              setOfficeEmail(e.target.value.slice(0,50))
            }}/>
            <div className="center">
                <button className="button-normal" onClick={handleVerificatin}>Send verification link</button>
            </div>
        </section>
    </section>
</section></div>
  )
}

export default SendVerification