import "../../custom.scss";

import React, { useState } from "react";


const AmountTransferred = (props) => {
  const [amount, setAmount] = useState("");
  const [amounterr, setAmountErr] = useState("");

  const handleSearch = () => {
    if (amount === "") {
      setAmountErr("Please enter amount");
      return;
    }
    props.history.push("/funds-transferred");
  };
  return (
    <div>
      {" "}
      <section className="container">
        <section className="wrapper">
          <h3 className="h3">Amount to be transferred</h3>
          <section className="light_bg_card">
            <section>
              <div className="center">
                <p>withdrawable amount is ₹37000</p>
              </div>
              <div className="ruppees">₹ </div>
              <input
                className="input-normal center fontsize24"
                placeholder="Enter amount"
                name="amount"
                value={amount}
                onChange={(e) => {
                  setAmount(e.target.value);
                  setAmountErr("");
                }}
              />
            </section>
            {amounterr ? <span className="error">{amounterr}</span> : null}
            <div className="center">
              <a className="underlineTextDark" href="">
                Terms and Conditions
              </a>
            </div>
          </section>
          <div className="center">
            <a className="underlineTextDark" href="">
              Charges and Fees
            </a>
          </div>
          <div className="center" style={{ marginTop: 30 }}>
            <button onClick={handleSearch} className="button-green">
              Agree and Send
            </button>
          </div>
        </section>
      </section>
    </div>
  );
};

export default AmountTransferred;
