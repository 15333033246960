import '../../custom.scss';
import yesIcon from '../../images/yes.png'
import React from 'react'

const FundsTransferred = (props) => {
    return (
        <div>
            <section className="container">
                <section className="wrapper">
                    <div className="center" style={{marginTop:120}}> <img src={yesIcon} alt="Yes"/></div>
                    <div className='marginTop40 center'><a className='noUnderlineText'>Your funds have been transferred</a></div>
                </section>
            </section>
        </div>
    )
}

export default FundsTransferred
