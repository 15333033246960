
import './custom.scss';
import React, { Component } from 'react';
import {BrowserRouter, Route} from 'react-router-dom'
import {Routes} from './routes';

class App extends Component {
    render(){
        return(
            <BrowserRouter>
                <Route path='/' component={Routes}/>
            </BrowserRouter>
        )
    }
    
}

export default App;
