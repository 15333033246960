import "../../custom.scss";

import React, { useState } from "react";

const CompanyHr = (props) => {
  const [hrname, setHrname] = useState("");
  const[nameerr,setNameError]=useState("")
  const [contact, setContact] = useState("");
  const[phoneerr,setPhoneerr]=useState("")
  const handleHrdetails=()=>{
if(hrname==="")
{
  setNameError("Enter your hr name");
  return;
}
if(contact.length===0)
{
  setPhoneerr("Plese enter Hr phone number");
  return;
}
if(contact.length!==10)
{
  setPhoneerr("Phone number should be 10 digits")
  return;
}
props.history.push('/take-me-home')
  }
  return (
    <div>
      {" "}
      <section className="container">
        <section className="wrapper">
          <h3 className="h3">You’re one step away from your salary advance</h3>
          <section className="light_bg_card">
            <input
              type="text"
              className="input-normal"
              placeholder="Name of your HR"
              value={hrname}
              onChange={(e) => {
                setHrname(e.target.value.slice(0, 50));
                setNameError("")
              }}
            />
            {nameerr?<span className="error">{nameerr}</span>:null}
            
            <input
              type="number"
              className="input-normal"
              placeholder="Mobile Number"
              value={contact}
              onChange={(e) => {
                setPhoneerr("")
                setContact(e.target.value.slice(0,10));
              }}
            />
            {phoneerr?<span className="error">{phoneerr}</span>:null}
            <div className="center">
              {" "}
              <button
                onClick={handleHrdetails}
                className="button-normal"
              >
                Next
              </button>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
};

export default CompanyHr;
