import '../../custom.scss';
import yesIcon from '../../images/yes.png'
import React from 'react'

const GetSalaryAdvance = (props) => {
    const handlegetSalary=()=>{
        props.history.push("/available-salary")
    }
    return (
        <div>
            <section className="container">
                <section className="wrapper">
                    <div className="center"> <img src={yesIcon} alt="Yes"/></div>
                    <div className='marginTop40 center'><a className='noUnderlineText'>Your email has been verified!</a></div>
                    <div className="center" style={{marginTop:200}}>
                        <button onClick={handlegetSalary} className="button-purple">Get Salary Advance Now</button>
                    </div>
                </section>
            </section>
        </div>
    )
}

export default GetSalaryAdvance
