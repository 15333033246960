import '../../custom.scss';
import thumb from '../../images/thumb.png'
import React from 'react'

const TakeMeHome = (props) => {
    return (
        <div>
            <section className="container">
                <section className="wrapper">
                    <div className="center" style={{marginTop:60}}> <img src={thumb} alt="Thumb"/></div>
                    <div className='marginTop40 center'>
                        <h3 className="h3">Awesome!</h3>
                        <div className='noUnderlineText marginTop40'>We will notify you once we have verified you!</div>
                    </div>
                    <div className="center" style={{marginTop:160}}>
                        <button onClick={()=>{
                        props.history.push("/")
                    }} className="button-purple">Take me home</button>
                    </div>
                </section>
            </section>
        </div>
    )
}

export default TakeMeHome
