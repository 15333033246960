/* eslint-disable */
import React from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'

import Search from "./Pages/CompanyListed/Search";
import SendVerification from './Pages/CompanyListed/SendVerification';
import Company from "./Pages/CompanyNotListed/Company";
import CompanyHr from "./Pages/CompanyNotListed/CompanyHr";
import CompanySelect from "./Pages/CompanyListed/CompanySelect";
import GetSalaryAdvance from "./Pages/CompanyListed/GetSalaryAdvance";
import VerifyNow from "./Pages/CompanyListed/VerifyNow";
import FundsTransferred from "./Pages/CompanyListed/FundsTransferred";
import TakeMeHome from "./Pages/CompanyListed/TakeMeHome";
import AmountTransferred from "./Pages/CompanyListed/AmountTransferred";
import TransactionHistory from './Pages/CompanyListed/TransactionHistory';
import AvailableSalary from "./Pages/CompanyListed/AvailableSalary";
import RecentTransactions from "./Pages/CompanyListed/RecentTransactions";
import PayNow from "./Pages/CompanyListed/PayNow";


export const Routes = () => {
    return (
        <div>
            <Switch>
                <Route exact path="/" component={Search}/>
                <Route exact path="/send-verification" component={SendVerification}/>
                <Route exact path="/company-select" component={CompanySelect}/>
                <Route exact path="/company" component={Company}/>
                <Route exact path="/company-hr" component={CompanyHr}/>
                <Route exact path="/get-salary-advance" component={GetSalaryAdvance}/>
                <Route exact path="/verify-now" component={VerifyNow}/>
                <Route exact path="/funds-transferred" component={FundsTransferred}/>
                <Route exact path="/take-me-home" component={TakeMeHome}/>
                <Route exact path="/amount-transferred" component={AmountTransferred}/>
                <Route exact path="/available-salary" component={AvailableSalary}/>
                <Route exact path="/transaction-history" component={TransactionHistory}/>
                <Route exact path="/recent-transaction" component={RecentTransactions}/>
                <Route exact path="/pay-now" component={PayNow}/>
            </Switch>
        </div>
    )
}