import "../../custom.scss";

import React, { useState } from "react";
import gas from "../../images/gas-tank.png";
import { QrReader } from "react-qr-reader";

const PayNow = (props) => {
  const [name, setName] = useState("");
  const [data, setData] = useState("No result");
  const [namerr, setNameerr] = useState("");

  const handleSearch = () => {
    if (name === "") {
      setNameerr("Please enter your company name");
      return;
    }
    props.history.push("/company-select", { companyName: name });
  };
  return (
    <div>
      {" "}
      <section className="container">
        <section className="wrapper">
          {/* <div className='center' style={{height: 200, background: "purple"}}> */}
          <div className="center">
            <QrReader
              facingMode="rear"
              onResult={(result, error) => {
                if (!!result) {
                  setData(result?.text);
                }

                if (!!error) {
                  console.info(error);
                }
              }}
              style={{ width: "100%" }}
            />
            <p>{data}</p>
          </div>
          <div className="center pt10">
            <div className="fontsize10 white fontweight6">Or</div>
          </div>
          <section className="light_bg_card cardPadding20">
            <div className="fontsize10 fontweight6">Search UPI</div>
            <input className="input-normal" placeholder="" />
          </section>
          <br />
          <section className="light_bg_card cardPadding20">
            <div className="fontweight6">Select Category</div>
            <div className="fontsize10 fontweight6 pt10">Utilities</div>
            <div className="utilities-box">
              <div>
                <img src={gas} alt="Gas" />
                <p>Electricity</p>
              </div>
              <div>
                <img src={gas} alt="Gas" />
                <p>Electricity</p>
              </div>
              <div>
                <img src={gas} alt="Gas" />
                <p>Electricity</p>
              </div>
              <div>
                <img src={gas} alt="Gas" />
                <p>Electricity</p>
              </div>
              <div>
                <img src={gas} alt="Gas" />
                <p>Electricity</p>
              </div>
              <div>
                <img src={gas} alt="Gas" />
                <p>Electricity</p>
              </div>
              <div>
                <img src={gas} alt="Gas" />
                <p>Electricity</p>
              </div>
              <div>
                <img src={gas} alt="Gas" />
                <p>Electricity</p>
              </div>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
};

export default PayNow;
