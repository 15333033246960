import "../../custom.scss";

import React, { useState } from "react";
import wallet from "../../images/wallet.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";

const cookies = new Cookies();
toast.configure();
const options = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 6000,
  limit: 0,
};

const AvailableSalary = (props) => {
    const email =cookies.get("officeEmail");
  const [toggle, setToggle] = useState(email==="vineet.daniel@paymeindia.in" ? true :false);
  const [togglecode, setTogglecode] = useState(true);

   const[stopMultirender,setStopMultirender]=useState(true)
   

  const handleSendtoBank = () => {
    props.history.push("/amount-transferred");
  };
  const handlePayNow = () => {
    props.history.push("/pay-now");
  };


  const handlePopUp = () => {
    setToggle(true);
    if (togglecode) {
      toast.error("Your minimum required attendance is short for availing this", {
        ...options,
      });
      setTogglecode(false);
      setTimeout(() => setTogglecode(true), 6000);
    }
  };
  if(toggle && stopMultirender){
    handlePopUp();
    setStopMultirender(false) 
  }

  return (
    <div>
      {" "}
      <section className="container">
        <section className="wrapper">
          <section className="light_bg_card purple_card">
            <div className="row-structure">
              <div>
                <h3 className="h3">Hi Karan</h3>
              </div>
              <div>
                <img src={wallet} alt="Wallet" />
              </div>
            </div>
          </section>
          <section className="light_bg_card cardPadding">
            <div className="center">
              <div className="fontsize10 fontweight6">
                Available salary advance of 20 days
              </div>
              <h1 className="fontsize24 pt10 fontweight6">₹ 50,000</h1>
            </div>
            {toggle ? (
              <div className="center">
                <button className="button-normal" style={{ color: "red" }}>
                  Send to Bank
                </button>
                &nbsp; &nbsp;
                <button className="button-normal" style={{ color: "red" }}>
                  Pay Now
                </button>
              </div>
            ) : (
              <div className="center">
                <button onClick={handleSendtoBank} className="button-normal">
                  Send to Bank
                </button>
                &nbsp; &nbsp;
                <button onClick={handlePayNow} className="button-normal">
                  Pay Now
                </button>
              </div>
            )}

            <div className="center pt25">
              <a className="underlineTextDark fontsize10" onClick={()=>{
                props.history.push("/transaction-history")
              }}>
                Recent Transactions
              </a>
            </div>
            <div className="tabular-form">
              <div className="row-structured">
                <div>18 Feb 2022</div>
                <div>+Rs.2000</div>
                <div>Earned Salary</div>
              </div>
              <div className="row-structured">
                <div>18 Feb 2022</div>
                <div>+Rs.2000</div>
                <div>Earned Salary</div>
              </div>
              <div className="row-structured">
                <div>18 Feb 2022</div>
                <div>+Rs.2000</div>
                <div>Earned Salary</div>
              </div>
            </div>
            <div className="center pt10">
              <a
                className="underlineTextDark fontsize10"
                href="/recent-transaction"
              >
                <strong>View more+</strong>
              </a>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
};

export default AvailableSalary;
