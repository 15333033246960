import '../../custom.scss';

import React, {useState} from "react";
import qcode from "../../images/qcode.png";

const RecentTransactions = (props) => {
   
    const handleSendtoBank = () => {
       
        props.history.push("/amount-transferred");
    };
    const handlePayNow = () => {
       
        props.history.push("/pay-now");
    };
    return (
        <div>
            {" "}
            <section className="container">
                <section className="wrapper">
                    <h3 className="h3">Recent Transactions</h3>
                    <section className="light_bg_card cardPadding20">
                        <div className='tabular-form alternate'>
                            <div className="row-structured head">
                                <div>Date</div>
                                <div>Cr/Dr</div>
                                <div>Remarks</div>
                            </div>
                            <div className="row-structured">
                                <div>18 Feb 2022</div>
                                <div>+Rs.2000</div>
                                <div>Earned Salary</div>
                            </div>
                            <div className="row-structured">
                                <div>18 Feb 2022</div>
                                <div>+Rs.2000</div>
                                <div>Earned Salary</div>
                            </div>
                            <div className="row-structured">
                                <div>18 Feb 2022</div>
                                <div>+Rs.2000</div>
                                <div>Earned Salary</div>
                            </div>
                            <div className="row-structured">
                                <div>18 Feb 2022</div>
                                <div>+Rs.2000</div>
                                <div>Earned Salary</div>
                            </div>
                            <div className="row-structured">
                                <div>18 Feb 2022</div>
                                <div>+Rs.2000</div>
                                <div>Earned Salary</div>
                            </div>
                            <div className="row-structured">
                                <div>18 Feb 2022</div>
                                <div>+Rs.2000</div>
                                <div>Earned Salary</div>
                            </div>

                        </div>
                        <div className="center border-dots" style={{marginTop:60}} ><img src={qcode} alt="Qr code"/></div>
                        <div className='center'>
                            <div className='fontsize10 fontweight6 pt25'>Available salary advance</div>
                            <h1 className="fontsize24 pt10 fontweight6">₹ 9,00,000</h1>
                        </div>
                        <div className='center'>
                           
                            <button onClick={handleSendtoBank} className="button-normal mt5">
                                Send to Bank
                            </button>
                            &nbsp; &nbsp;
                            <button onClick={handlePayNow} className="button-normal mt5">
                                Pay Now
                            </button>
                        </div>
                    </section>
                </section>
            </section>
        </div>
    );
};

export default RecentTransactions
