import "../../custom.scss";

import React, { useState } from "react";

const CompanySelect = (props) => {
    const[companyName,setCompanyName]=useState(props?.location?.state?.companyName);
    
  const handleCompany = (e) => {
    props.history.push("/send-verification",{companyName:e.target.value});
  };
  const handleRegisterCompany=()=>{
    props.history.push("/company");
  }
  return (
    <div>
      <section className="container">
        <section className="wrapper">
          <h3 className="h3">Select your company</h3>
          <section className="light_bg_card">
            <select
              name="company-select"
              className="company-select"
              onChange={handleCompany}
            >
              <option value={companyName}>{companyName}</option>
              <option value="Infosys Global">Infosys Global</option>
              <option value="TATA Company">TATA Company</option>
              <option value="Payme India">Payme India</option>
              <option value="Microsoft">Microsoft</option>
              <option value="Adobe">Adobe</option>
            </select>
          </section>
          <div className="marginTop40 center">
            <a className="underlineText" onClick={handleRegisterCompany}>Can’t find your company ?</a>
          </div>
        </section>
      </section>
    </div>
  );
};

export default CompanySelect;
