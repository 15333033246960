import '../../custom.scss';

import React, { useState } from "react";
const TransactionHistory = (props) => {
 
  
  return (
    <div>
      {" "}
      <section className="container">
        <section className="wrapper">
          <h3 className="h3">Recent Transaction History</h3>
          <section className="light_bg_card">
            <input type="date" />

          <div className="center" style={{marginTop:160}}>
          <div>
       
      </div>

                    </div>
          </section>
        </section>
      </section>
    </div>
  );
};

export default TransactionHistory