import '../../custom.scss';
import yesIcon from '../../images/yes.png'
import React from 'react'

const VerifyNow = (props) => {
    return (
        <div>
            <section className="container">
                <section className="wrapper">
                    <div className='marginTop40 center'><a className='noUnderlineText'>Your email has been verified!</a></div>
                    <div className="center" style={{marginTop:50}}>
                        <button onClick={()=>{
                        props.history.push("/send-verification")
                    }} className="button-purple">Let’s Go</button>
                    </div>
                </section>
            </section>
        </div>
    )
}

export default VerifyNow
